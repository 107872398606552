<template>
  <button class="app-button-layout" :class="{ disabled: disabled, [color]: true, small: small }">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'AppButtonLayout',
  props: {
    color: {
      type: String,
      required: false,
      default () {
        return 'primary'
      },
      validator (value) {
        return ['primary', 'secondary', 'tertiary', 'alternate', 'error', 'alternate-secondary', 'white'].includes(value)
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    },
    small: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin outline ($color) {
  background-color: #fff;
  color: $color;
  border: 1px solid $color;
}

button.app-button-layout {
  font-family: $ff;
  border-radius: 150px;
  padding: $space-sm $space-ml;
  min-height: 60px;
  color: #fff;
  transition: $color-transition;
  text-transform: uppercase;
  font-size: $fz-ml;
  font-weight: $fw-l;
  &.small {
    padding: $space-s $space-sm;
    font-size: $fz-xs;
    min-height: 40px;
    text-transform: none;
  }
  &.disabled {
    cursor: default;
  }
  &.white {
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
    &:hover {
      background-color: #fff;
      color: $c-text;
    }
    &.disabled {
      @include outline($c-text-light);
    }
  }
  &.primary {
    background-color: $c-primary;
    &:hover {
      background-color: darken($c-primary, 5);
    }
    &.disabled {
      background-color: #b5babe;
    }
  }
  &.secondary {
    background-color: $c-secondary;
    &:hover {
      background-color: darken($c-secondary, 10);
    }
    &.disabled {
      @include outline($c-secondary);
    }
  }
  &.alternate {
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
    &:hover {
      background-color: #fff;
      color: $c-secondary;
    }
    &.disabled {
      color: rgba($c-text, .4);
      border-color: rgba($c-text, .4);
    }
  }
  &.error {
    background-color: $c-error;
    color: #fff;
    border: 2px solid #fff;
    &:hover {
      background-color: #fff;
      color: $c-error;
    }
    &.disabled {
      background-color: #fff;
      color: $c-error;
      border-color: $c-error;
    }
  }
  &.alternate-secondary {
    background-color: transparent;
    color: $c-secondary;
    border: 2px solid $c-secondary;
    &:hover {
      background-color: $c-secondary;
      color: #fff;
    }
  }
}
</style>
