<template>
  <div :class="'notification-box'">
    <transition-group :css="false"
      v-on:beforeEnter="onBeforeEnter"
      v-on:enter="onEnter"
      v-on:leave="onLeave">
      <notification-message v-for="notification in notifications"
        :notification="notification"
        :delay="notification.delay ? notification.delay : delay"
        :key="notification.id"
        v-on:closeNotification="removeNotification"
      ></notification-message>
    </transition-group>
  </div>
</template>

<script>
import notificationMessage from './NotificationMessage.vue'
import gsap from 'gsap'
import { mapState } from 'vuex'

export default {
  name: 'notificationBox',
  components: {
    notificationMessage
  },
  props: {
    delay: {
      type: Number,
      required: false,
      default () {
        return 5000
      }
    }
  },
  computed: {
    ...mapState('notification', ['notifications'])
  },
  methods: {
    removeNotification (notification) {
      this.$store.dispatch('notification/removeNotification', notification)
    },
    onBeforeEnter (el) {
      gsap.set(el, { opacity: 0 })
    },
    onEnter (el, done) {
      gsap.to(el, { duration: 0.5, opacity: 1, onComplete: done })
    },
    onLeave (el, done) {
      gsap.to(el, { duration: 0.5, opacity: 0, onComplete: done })
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-box {
  position: fixed;
  z-index: 20000;
  width: 90%;
  max-width: $bp-tablet;
  top: $space-m;
  left: 50%;
  transform: translateX(-50%);
  .notification-message + .notification-message {
    margin-top: $space-s;
  }
}
</style>
