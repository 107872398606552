import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import { Vue3Mq } from 'vue3-mq'

const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(Vue3Mq, {
    breakpoints: { phone: 0, tablet: 540, desktop: 768, desktopBig: 1024, all: 1280 }
  })
app.mount('#app')
