import userServiceAPI from '@/modules/user/api/userServiceAPI'
import apiConfig from '@/api/apiConfig'

const userStore = {
  namespaced: true,
  state: {
    userToken: null,
    user: null
  },
  getters: {
    isLoggedIn (state) {
      return !!state.userToken && !!state.user
    },
    getFirstName (state) {
      return state.user?.first_name
    },
    getLastName (state) {
      return state.user?.last_name
    },
    getUserInitials (state, getters) {
      return getters.getFirstName.charAt(0) + '.' + getters.getLastName.charAt(0)
    }
  },
  mutations: {
    setUserToken (state, token) {
      state.userToken = token
      apiConfig.setAuthHeader(token)
      sessionStorage.setItem('userToken', JSON.stringify(token))
    },
    setUser (state, user) {
      state.user = user
      sessionStorage.setItem('user', JSON.stringify(user))
    }
  },
  actions: {
    async login ({ dispatch, commit }, credentials) {
      const response = await userServiceAPI.login(credentials)
      commit('setUserToken', response.data.token)
      await dispatch('fetchUser')
    },
    async fetchUser ({ commit, dispatch }) {
      const response = await userServiceAPI.getUser()
      commit('setUser', response.data)
      await dispatch('training/initUserProgress', null, { root: true })
    },
    async verifyUserToken ({ commit }, token) {
      await userServiceAPI.verifyToken(token)
      commit('setUserToken', token)
    },
    logout: ({ commit }) => {
      commit('setUserToken', null)
      commit('setUser', null)
      sessionStorage.removeItem('userToken')
    }
  }
}

export default userStore
