import axios from 'axios'

const apiConfig = {
  apiUrl: process.env.VUE_APP_URL_API,
  setAuthHeader (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  }
}

export default apiConfig
