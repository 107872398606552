<template>
  <div class="sso" v-if="ssoError">
    <div class="message">
      {{ $t('global.errors.expiredToken') }}
    </div>
    <app-button-layout color="secondary" @click="redirectToOscar">
      {{ $t('global.navigation.goToLogin') }}
    </app-button-layout>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { onBeforeMount, ref } from 'vue'
import redirectToOscar from '@/utils/redirectToOscar'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import { useStore } from 'vuex'
import VIEWS_NAMES from '@/constants/VIEWS_NAMES'

export default {
  name: 'sso',
  components: { AppButtonLayout },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const ssoError = ref(false)
    const store = useStore()
    const goToModule = (targetModule) => {
      if (targetModule === 'test') {
        router.push({
          name: VIEWS_NAMES.FINAL_TEST
        })
      } else {
        router.push({
          name: VIEWS_NAMES.TRAINING_SUMMARY,
          params: {
            moduleId: store.state.training.modules[targetModule].id
          }
        })
      }
    }
    onBeforeMount(async () => {
      try {
        await store.dispatch('user/verifyUserToken', route.params.userToken)
        await store.dispatch('user/fetchUser')
        const targetModule = route.params.targetModule
        goToModule(targetModule)
      } catch (error) {
        console.log('sso error')
        ssoError.value = true
        console.error(error)
        store.dispatch('user/logout')
      }
    })
    return { ssoError, redirectToOscar }
  }
}
</script>

<style lang="scss">
.sso {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .app-button-layout {
    margin-top: $space-m;
  }
}
</style>
