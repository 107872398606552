import { createI18n } from 'vue-i18n'
import camelCase from 'lodash/camelCase'
import globalMessages from '@/locales/fr/global/globalMessages'
import homeMessages from '@/locales/fr/home/homeMessages'
import trainingMessages from '@/locales/fr/training/trainingMessages'

// Initialize messages object
const messages = {
  fr: {
    global: globalMessages,
    home: homeMessages,
    training: trainingMessages
  }
}

const i18n = createI18n({
  locale: 'fr',
  messages: {
    ...messages
  }
})

const messagesToLazyload = []

export function lazyLoadLocales (messagesFolders = messagesToLazyload) {
  return new Promise((resolve, reject) => {
    const localesPromises = []
    const messages = {}
    messagesFolders.forEach(fileName => {
      localesPromises.push(
        import('@/locales/fr/' + fileName + '/' + camelCase(fileName) + 'Messages.js')
          .then(message => {
            messages[camelCase(fileName)] = message.default
          })
      )
    })

    Promise.all(localesPromises)
      .then(() => {
        resolve(messages)
      })
      .catch(e => {
        reject(e)
      })
  })
}

export default i18n
