const globalMessages = {
  oscar: 'OSCAR',
  appName: 'formation @:global.oscar',
  appUrl: 'https://formation.programme-oscar-cee.fr',
  textPlaceholder: '???',
  source: 'Source',
  videoTuto: 'Découvrir la formation en vidéo',
  maj: '(mise à jour le {date})',
  pageNotFound: {
    title: 'Page introuvable...',
    error: 'Une erreur est survenue lors de la navigation !'
  },
  language: 'Français',
  navigation: {
    next: 'Suivant',
    prev: 'Retour',
    home: 'Accueil',
    validate: 'Valider',
    burger: 'menu',
    dashboard: 'Tableau de bord',
    backToDashboard: 'Revenir au tableau de bord',
    userHome: 'Accéder à mon espace',
    goToRegister: 'Je m\'inscris',
    goToLogin: 'Je me connecte',
    logout: 'Me déconnecter',
    backToHome: 'Revenir à l\'accueil',
    cancel: 'Annuler',
    hide: 'Masquer le détail',
    show: 'Afficher le détail',
    resume: 'continuer',
    close: 'fermer',
    resumeLater: 'reprendre plus tard',
    giveOpinion: 'donner mon avis',
    terminate: 'Terminer'
  },
  errors: {
    expiredToken: 'Votre session à expirée merci de vous reconnecter',
    accessDenied: 'Vous devez être connecté pour accéder à cette page, vous avez été redirigé vers la page de connexion',
    fetchData: 'Une erreur est survenue lors de la récupération des données',
    progressSaveError: 'Une erreur est survenue lors de la sauvegarde de votre progression sur le MOOC.<br>' +
      "Merci de contacter contact.oscar{'@'}atee.fr et copier le message suivant&nbsp;:<br><br>{errorMessage}"
  },
  validations: {
    required: 'Le champs {name} est obligatoire',
    email: 'Merci de saisir une adresse e-mail valide',
    minLength: 'Merci de saisir au moins {min} caractères',
    maxLength: 'Merci de saisir {max} caractères maximum',
    numeric: 'Merci de saisir uniquement des chiffres',
    requiredIf: 'Merci de renseigner ce champs',
    mustBeTrue: 'Merci de cocher cette case',
    mustStartWithZero: 'Merci de saisir une valeur qui commence par zéro'
  },
  legals: {
    dataRights: 'Politique de gestion des données personnelles',
    legalMentions: 'Mentions légales',
    copyright: '@:global.oscar © 2022 - Tous droits réservés',
    cookieBanner: 'Ce site n\'utilise aucun traceur ou cookie tiers. Seuls des cookies nécessaires au fonctionnement du site sont utilisés.'
  },
  imageAlts: {
    default: 'image',
    background: 'Image de fond',
    playIcon: 'icône "jouer la vidéo"',
    loadingIcon: 'icône "chargement"',
    megaphoneIcon: 'icône "megaphone"',
    crossIcon: 'icône "Croix"',
    returnIcon: 'icône "Flèche retour"',
    lockIcon: 'icône "cadenas"',
    rightArrow: 'icône "flèche vers la droite',
    leftArrow: 'icône "flèche vers la gauche',
    checkIcon: 'icône "check',
    franceRenovLogo: 'Logo France Rénov\'',
    peopleIllu: 'Illustration silhouette',
    moneyIllu: 'Illustration pile de pièces',
    pinHomeIllu: 'Illustration maison individuelle',
    kwhcumacExplanation: 'kwh = énergie économisée, cum = cumulée sur la durée de vie, ac = actualisée pour prendre en compte les pertes de performance.',
    calculCee: 'cee (kWh cumac) x valorisation (€ / MWhc)',
    warning: 'Panneau attention',
    thumbIcon: 'icône "pouces vers le haut"',
    maprimerenovLogo: 'Logo MaPrimeRenov\'',
    logementIllu: 'Illustration quel logement ?',
    quiIllu: 'Illustration silhouette visage',
    travauxIllu: 'Illustration outils',
    obtentionIllu: 'Illustration obtention d\'une aide',
    tva55Logo: 'Logo TVA 5,5%',
    ceeLogo: 'Logo aide CEE',
    ecoPtzLogo: 'Logo aide ECO PTZ',
    regleCumulTresModeste: 'Illustration cumul entre maprimerenov et cee/coup de pouce ménage très modeste inférieur ou égale à 90% de la dépense éligible',
    regleCumulModeste: 'Illustration cumul entre maprimerenov et cee/coup de pouce ménage modeste inférieur ou égale à 75% de la dépense éligible',
    regleCumulIntermediaire: 'Illustration cumul entre maprimerenov et cee/coup de pouce ménage intermédiaire inférieur ou égale à 60% de la dépense éligible',
    regleCumulAise: 'Illustration cumul entre maprimerenov et cee/coup de pouce ménage aux revenus supérieurs, inférieur ou égale à 40% de la dépense éligible',
    incompatibiliteIllu: 'Illustration aides par geste de travaux incompatibles avec les aides à la rénovation globale',
    montantsMpr: 'Illustration aides par geste de travaux incompatibles avec les aides à la rénovation globale',
    illuParcoursAccompagne: 'Illustration parcours accompagné',
    illuDecarbonation: 'Illustration décarbonation du chauffage',
    loupeIcon: 'Illustration loupe',
    ecoIllu: 'Illustration économies énergie',
    dpeIllu: 'Illustration DPE',
    sautClasseIllu: 'Illustration saut de classe',
    forfaitRenoGlobale: 'Illustration Forfait Rénovation globale',
    illuBonusMpr: 'Illustration bonus MaPrimeRénov\'',
    logoMprCopro: 'Logo MaPrimeRénov\' Copropriété',
    ceeIncluIllu: 'Illustration CEE inclus',
    ceeCumulIllu: 'Illustration CEE cumulable',
    aidesLocalIllu: 'Illustration aides locales'
  },
  form: {
    dropdownPlaceholder: 'Choisissez',
    notRequiredField: 'facultatif'
  }
}

export default globalMessages
