const VIEWS_NAMES = {
  SSO: 'sso',
  TRAINING_SUMMARY: 'training-summary',
  TRAINING: 'training',
  FINAL_TEST: 'final-test',
  NOT_FOUND: 'not-found',
  PANORAMA_AIDES_INFOGRAPHY: 'panorama-aides-infography',
  ERROR_TOKEN: 'error-token'
}

export default VIEWS_NAMES
