<template>
  <app-header></app-header>
  <div class="view-wrapper">
    <router-view/>
  </div>
  <app-footer></app-footer>
  <notification-box></notification-box>
  <div id="modals"></div>
</template>

<script>
import AppHeader from '@/components/layout/AppHeader'
import AppFooter from '@/components/layout/AppFooter'
import NotificationBox from '@/components/notifications/NotificationBox'
import VIEWS_NAMES from '@/constants/VIEWS_NAMES'
import useUserAutoLogin from '@/modules/user/composables/useUserAutoLogin'
import { onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { routes } from '@/router/index.js'
import { useRouter } from 'vue-router'

export default {
  name: 'App',
  components: { AppFooter, AppHeader, NotificationBox },
  setup () {
    const { autoLogin } = useUserAutoLogin()
    const store = useStore()
    const router = useRouter()

    function checkPathnamesWithAuthNotRequired (pathname) {
      return routes
        .filter(route => route.meta.requiresAuth === false)
        .some(route => pathname.startsWith('/' + route.path.split('/')[1]))
    }

    if (!checkPathnamesWithAuthNotRequired(window.location.pathname)) {
      autoLogin()
      console.log('autologin')
      onBeforeMount(async () => {
        try {
          await store.dispatch('user/verifyUserToken', store.state.user.userToken)
          await store.dispatch('user/fetchUser')
        } catch (error) {
          store.dispatch('user/logout')
          router.push({ name: VIEWS_NAMES.ERROR_TOKEN })
          console.error(error)
        }
      })
    }
    return { VIEWS_NAMES }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Montserrat+Alternates:wght@300;800&display=swap');
@import "./styles/main";
@import "./styles/resets";
@import "./styles/appForm";
@import "./styles/userFormGrid";
@import "./styles/training";
@import "./styles/energyLabel";

#app {
  font-family: $ff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $c-text;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  .view-wrapper {
    width: 100%;
    flex-grow: 1;
  }
}

#modals {
  position: relative;
}
</style>
