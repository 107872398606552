<template>
  <footer class="app-footer">
    <div class="footer-content">
      <div class="footer-links">
        <a class="footer-link" rel="noopener" :href="oscarUrls.legalMentions" target="_blank">
          {{ $t('global.legals.legalMentions') }}
        </a>
        <a class="footer-link" rel="noopener" :href="oscarUrls.dataRights" target="_blank">
          {{ $t('global.legals.dataRights') }}
        </a>
      </div>
      <div class="copyright">
        {{ $t('global.legals.copyright') }}
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  setup () {
    const oscarUrls = {
      legalMentions: 'https://plateforme.programme-oscar-cee.fr/pages/mentions-legales',
      dataRights: 'https://plateforme.programme-oscar-cee.fr/pages/politique-donnees-personnelles'
    }
    return { oscarUrls }
  }
}
</script>

<style lang="scss" scoped>
.app-footer {
  width: 100%;
  color: #fff;
  font-family: $ff-text;
  font-weight: $fw-m;
  font-size: $fz-s;
  background-color: #021a26;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -23px;
    left: 0;
    height: 24px;
    width: 100%;
    background-color: #021a26;
    border-top-left-radius: $radius-xl;
    border-top-right-radius: $radius-xl;
  }
  .footer-content {
    max-width: $bp-desktop-big;
    margin: 0 auto;
    padding: 0 $space-m 23px $space-m;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    .footer-links {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .copyright {
      margin-top: $space-m;
    }
  }
  .footer-link {
    & + .footer-link {
      margin-top: $space-m;
    }
    color: #fff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

@media (min-width: $bp-tablet) {
  .app-footer {
    flex-direction: row;
    .footer-content {
      flex-direction: row;
      .footer-links {
        flex-direction: row;
      }
      .copyright {
        margin-top: 0;
      }
    }
    .footer-link {
      & + .footer-link {
        margin-top: 0;
        margin-left: $space-xl;
      }
    }
  }
}
</style>
