import { createStore } from 'vuex'
import userStore from '@/modules/user/store/userStore'
import notificationStore from '@/store/notificationStore'
import trainingStore from '@/store/trainingStore'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user: userStore,
    notification: notificationStore,
    training: trainingStore
  }
})
