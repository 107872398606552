import i18n from '@/i18n'

export const STEPS = {
  INTRO: {
    ID: 'intro',
    PATH: 'introduction',
    LABEL: i18n.global.t('training.steps.intro')
  },
  LESSON: {
    ID: 'lesson',
    PATH: 'cours',
    LABEL: i18n.global.t('training.steps.lesson')
  },
  ACTIVITY: {
    ID: 'activity',
    PATH: 'activité',
    LABEL: i18n.global.t('training.steps.activity')
  }
}

export const trainingModuleList = ['module1', 'module2', 'module3']

export const trainingModules = {
  module1: {
    id: 'module1',
    path: 'module-1',
    name: i18n.global.t('training.module1.name'),
    label: i18n.global.t('training.module1.title'),
    parts: ['Module1Part1', 'Module1Part2', 'Module1Part3', 'Module1Part4', 'Module1Part5']
  },
  module2: {
    id: 'module2',
    path: 'module-2',
    name: i18n.global.t('training.module2.name'),
    label: i18n.global.t('training.module2.title'),
    parts: ['Module2Part1', 'Module2Part2', 'Module2Part3']
  },
  module3: {
    id: 'module3',
    path: 'module-3',
    name: i18n.global.t('training.module3.name'),
    label: i18n.global.t('training.module3.title'),
    parts: ['Module3Part1', 'Module3Part2', 'Module3Part3']
  }
}

export const trainingParts = {
  Module1Part1: {
    id: 'Module1Part1',
    path: 'partie-1',
    label: i18n.global.t('training.module1.parts.part1'),
    steps: ['Module1Part1Intro', 'Module1Part1Lesson', 'Module1Part1Activity']
  },
  Module1Part2: {
    id: 'Module1Part2',
    path: 'partie-2',
    label: i18n.global.t('training.module1.parts.part2'),
    steps: ['Module1Part2Intro', 'Module1Part2Lesson', 'Module1Part2Activity']
  },
  Module1Part3: {
    id: 'Module1Part3',
    path: 'partie-3',
    label: i18n.global.t('training.module1.parts.part3'),
    steps: ['Module1Part3Intro', 'Module1Part3Lesson']
  },
  Module1Part4: {
    id: 'Module1Part4',
    path: 'partie-4',
    label: i18n.global.t('training.module1.parts.part4'),
    steps: ['Module1Part4Intro', 'Module1Part4Lesson']
  },
  Module1Part5: {
    id: 'Module1Part5',
    path: 'partie-5',
    label: i18n.global.t('training.module1.parts.part5'),
    steps: ['Module1Part5Intro', 'Module1Part5Lesson']
  },
  Module2Part1: {
    id: 'Module2Part1',
    path: 'partie-1',
    label: i18n.global.t('training.module2.parts.part1'),
    steps: ['Module2Part1Intro', 'Module2Part1Lesson', 'Module2Part1Activity']
  },
  Module2Part2: {
    id: 'Module2Part2',
    path: 'partie-2',
    label: i18n.global.t('training.module2.parts.part2'),
    steps: ['Module2Part2Intro', 'Module2Part2Lesson']
  },
  Module2Part3: {
    id: 'Module2Part3',
    path: 'partie-3',
    label: i18n.global.t('training.module2.parts.part3'),
    steps: ['Module2Part3Intro', 'Module2Part3Lesson', 'Module2Part3Activity']
  },
  Module3Part1: {
    id: 'Module3Part1',
    path: 'partie-1',
    label: i18n.global.t('training.module3.parts.part1'),
    steps: ['Module3Part1Intro', 'Module3Part1Lesson']
  },
  Module3Part2: {
    id: 'Module3Part2',
    path: 'partie-2',
    label: i18n.global.t('training.module3.parts.part2'),
    steps: ['Module3Part2Intro', 'Module3Part2Lesson']
  },
  Module3Part3: {
    id: 'Module3Part3',
    path: 'partie-3',
    label: i18n.global.t('training.module3.parts.part3'),
    steps: ['Module3Part3Intro', 'Module3Part3Lesson', 'Module3Part3Activity']
  }
}

export const trainingSteps = {
  Module1Part1Intro: {
    id: 'Module1Part1Intro',
    path: STEPS.INTRO.PATH,
    label: STEPS.INTRO.LABEL
  },
  Module1Part1Lesson: {
    id: 'Module1Part1Lesson',
    path: STEPS.LESSON.PATH,
    label: STEPS.LESSON.LABEL
  },
  Module1Part1Activity: {
    id: 'Module1Part1Activity',
    path: STEPS.ACTIVITY.PATH,
    label: STEPS.ACTIVITY.LABEL
  },
  Module1Part2Intro: {
    id: 'Module1Part2Intro',
    path: STEPS.INTRO.PATH,
    label: STEPS.INTRO.LABEL
  },
  Module1Part2Lesson: {
    id: 'Module1Part2Lesson',
    path: STEPS.LESSON.PATH,
    label: STEPS.LESSON.LABEL
  },
  Module1Part2Activity: {
    id: 'Module1Part2Activity',
    path: STEPS.ACTIVITY.PATH,
    label: STEPS.ACTIVITY.LABEL
  },
  Module1Part3Intro: {
    id: 'Module1Part3Intro',
    path: STEPS.INTRO.PATH,
    label: STEPS.INTRO.LABEL
  },
  Module1Part3Lesson: {
    id: 'Module1Part3Lesson',
    path: STEPS.LESSON.PATH,
    label: STEPS.LESSON.LABEL
  },
  Module1Part4Intro: {
    id: 'Module1Part4Intro',
    path: STEPS.INTRO.PATH,
    label: STEPS.INTRO.LABEL
  },
  Module1Part4Lesson: {
    id: 'Module1Part4Lesson',
    path: STEPS.LESSON.PATH,
    label: STEPS.LESSON.LABEL
  },
  Module1Part5Intro: {
    id: 'Module1Part5Intro',
    path: STEPS.INTRO.PATH,
    label: STEPS.INTRO.LABEL
  },
  Module1Part5Lesson: {
    id: 'Module1Part5Lesson',
    path: STEPS.LESSON.PATH,
    label: STEPS.LESSON.LABEL
  },
  Module2Part1Intro: {
    id: 'Module2Part1Intro',
    path: STEPS.INTRO.PATH,
    label: STEPS.INTRO.LABEL
  },
  Module2Part1Lesson: {
    id: 'Module2Part1Lesson',
    path: STEPS.LESSON.PATH,
    label: STEPS.LESSON.LABEL
  },
  Module2Part1Activity: {
    id: 'Module2Part1Activity',
    path: STEPS.ACTIVITY.PATH,
    label: STEPS.ACTIVITY.LABEL
  },
  Module2Part2Intro: {
    id: 'Module2Part2Intro',
    path: STEPS.INTRO.PATH,
    label: STEPS.INTRO.LABEL
  },
  Module2Part2Lesson: {
    id: 'Module2Part2Lesson',
    path: STEPS.LESSON.PATH,
    label: STEPS.LESSON.LABEL
  },
  Module2Part3Intro: {
    id: 'Module2Part3Intro',
    path: STEPS.INTRO.PATH,
    label: STEPS.INTRO.LABEL
  },
  Module2Part3Lesson: {
    id: 'Module2Part3Lesson',
    path: STEPS.LESSON.PATH,
    label: STEPS.LESSON.LABEL
  },
  Module2Part3Activity: {
    id: 'Module2Part3Activity',
    path: STEPS.ACTIVITY.PATH,
    label: STEPS.ACTIVITY.LABEL
  },
  Module3Part1Intro: {
    id: 'Module3Part1Intro',
    path: STEPS.INTRO.PATH,
    label: STEPS.INTRO.LABEL
  },
  Module3Part1Lesson: {
    id: 'Module3Part1Lesson',
    path: STEPS.LESSON.PATH,
    label: STEPS.LESSON.LABEL
  },
  Module3Part2Intro: {
    id: 'Module3Part2Intro',
    path: STEPS.INTRO.PATH,
    label: STEPS.INTRO.LABEL
  },
  Module3Part2Lesson: {
    id: 'Module3Part2Lesson',
    path: STEPS.LESSON.PATH,
    label: STEPS.LESSON.LABEL
  },
  Module3Part3Intro: {
    id: 'Module3Part3Intro',
    path: STEPS.INTRO.PATH,
    label: STEPS.INTRO.LABEL
  },
  Module3Part3Lesson: {
    id: 'Module3Part3Lesson',
    path: STEPS.LESSON.PATH,
    label: STEPS.LESSON.LABEL
  },
  Module3Part3Activity: {
    id: 'Module3Part3Activity',
    path: STEPS.ACTIVITY.PATH,
    label: STEPS.ACTIVITY.LABEL
  }
}
