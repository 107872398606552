import axios from 'axios'
import apiConfig from '@/api/apiConfig'

const userServiceAPI = {
  login (credentials) {
    return axios.post(
      apiConfig.apiUrl + 'security/login',
      credentials
    )
  },

  verifyToken (token) {
    return axios.get(apiConfig.apiUrl + 'security/validate-token/' + token)
  },

  getUser () {
    return axios.get(apiConfig.apiUrl + 'user')
  }
}

export default userServiceAPI
