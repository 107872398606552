import { trainingSteps, trainingModuleList, trainingModules, trainingParts } from '@/data/trainingModules'
import TRAINING_STATUS from '@/constants/TRAINING_STATUS'
import trainingProgressServiceAPI from '@/api/trainingProgressServiceAPI'
import USER_LEVELS from '@/constants/USER_LEVELS'

const trainingStore = {
  namespaced: true,
  state: {
    testVersion: '1.2', // MUST BE IN SYNC WITH THE VALUE IN THE BACKEND
    moduleList: trainingModuleList,
    modules: trainingModules,
    parts: trainingParts,
    steps: trainingSteps,
    finishedSteps: [],
    isDatasLoaded: false,
    stepsDatas: {},
    partsDatas: {},
    finalTestDatas: {},
    isMoocValid: null
  },
  getters: {
    getModuleSet (state) {
      return state.moduleList.map(moduleId => state.modules[moduleId])
    },
    getModulePartSet: (state) => (moduleId) => {
      return state.modules[moduleId].parts.map(partId => state.parts[partId])
    },
    modulesStatus (state, getters) {
      // check si les modules sont terminés
      const toReturn = {}
      state.moduleList
        .map(moduleId => state.modules[moduleId])
        .forEach((module) => {
          if (getters.isModuleFinished(module.id)) {
            toReturn[module.id] = TRAINING_STATUS.FINISHED
          } else if (getters.isModuleStarted(module.id)) {
            toReturn[module.id] = TRAINING_STATUS.STARTED
          } else if (getters.isModuleLocked(module.id)) {
            toReturn[module.id] = TRAINING_STATUS.LOCKED
          } else {
            toReturn[module.id] = TRAINING_STATUS.TODO
          }
        })
      return toReturn
    },
    progress (state) {
      return Math.round(state.finishedSteps.length / Object.keys(state.steps).length * 100)
    },
    isModuleLocked: (state, getters) => (moduleId) => {
      // Un module est locked si le module d'avant n'est pas finished
      // Le premier module ne peux pas être locked
      const moduleIndexInModuleList = state.moduleList.indexOf(moduleId)
      return moduleIndexInModuleList === 0
        ? false
        : !getters.isModuleFinished(state.modules[state.moduleList[moduleIndexInModuleList - 1]].id)
    },
    isModuleFinished: (state, getters) => (moduleId) => {
      // Un module est finished si toutes ses parts son finished
      return state.modules[moduleId].parts.every(partId => getters.isPartFinished(partId))
    },
    isModuleStarted: (state, getters) => (moduleId) => {
      // Un module est started si au moins une de ses parts est started
      return state.modules[moduleId].parts.some(partId => getters.isPartStarted(partId))
    },
    isPartLocked: (state, getters) => (moduleId, partId) => {
      // Une part est locked si son module est locked
      if (getters.isModuleLocked(moduleId)) return true
      // Une part est locked si la part d'avant n'est pas finished
      // La première part ne peux pas être locked
      const partIndexInModule = state.modules[moduleId].parts.indexOf(partId)
      const previousPart = state.parts[state.modules[moduleId].parts[partIndexInModule - 1]]
      return partIndexInModule === 0
        ? false
        : !getters.isPartFinished(previousPart.id)
    },
    isPartStarted: (state) => (partId) => {
      // Une part est started si au moins une de ses step est finished
      return state.parts[partId].steps.some(stepId => state.finishedSteps.includes(stepId))
    },
    isPartFinished: (state) => (partId) => {
      // Une part est finished si toutes ses steps sont finished
      return state.parts[partId].steps.every(stepId => state.finishedSteps.includes(stepId))
    },
    isPartTodo: (state, getters) => (moduleId, partId) => {
      return !getters.isPartStarted(partId) && !getters.isPartLocked(moduleId, partId)
    },
    isTrainingFinished (state, getters) {
      return state.moduleList.every(moduleId => getters.isModuleFinished(moduleId))
    },
    isFinalTestSuccess (state) {
      return state.finalTestDatas?.scoreActivity === USER_LEVELS.GOOD
    }
  },
  mutations: {
    setFinishedSteps (state, finishedSteps) {
      state.finishedSteps = finishedSteps
    },
    pushFinishedStep (state, finishedStepId) {
      state.finishedSteps.push(finishedStepId)
    },
    setStepData (state, { stepId, key, data }) {
      if (!state.stepsDatas[stepId]) {
        state.stepsDatas[stepId] = {}
      }
      state.stepsDatas[stepId][key] = data
    },
    setStepsDatas (state, datas) {
      state.stepsDatas = datas
    },
    setPartData (state, { partId, key, data }) {
      if (!state.partsDatas[partId]) {
        state.partsDatas[partId] = {}
      }
      state.partsDatas[partId][key] = data
    },
    setFinalTestDatas (state, data) {
      state.finalTestDatas = data
    },
    setFinalTestData (state, { key, value }) {
      state.finalTestDatas[key] = value
    },
    setPartsDatas (state, datas) {
      state.partsDatas = datas
    },
    setIsDatasLoaded (state, isDataLoaded) {
      state.isDatasLoaded = isDataLoaded
    },
    setIsMoocValid (state, validity) {
      state.isMoocValid = validity
    }
  },
  actions: {
    async stepFinished ({ state, commit, dispatch }, { stepId }) {
      if (!state.finishedSteps.some(step => step === stepId)) {
        commit('pushFinishedStep', stepId)
      }
      await dispatch('saveProgress')
    },
    saveStepData ({ dispatch, commit }, { stepId, key, data }) {
      commit('setStepData', { stepId, key, data })
    },
    savePartData ({ dispatch, commit }, { partId, key, data }) {
      commit('setPartData', { partId, key, data })
    },
    async saveProgress ({ state, getters }) {
      await trainingProgressServiceAPI.setProgress({
        modulesStatus: getters.modulesStatus,
        finishedSteps: state.finishedSteps,
        stepsDatas: state.stepsDatas,
        partsDatas: state.partsDatas,
        finalTestDatas: state.finalTestDatas
      })
    },
    async initUserProgress ({ commit }) {
      commit('setIsDatasLoaded', false)
      const response = await trainingProgressServiceAPI.getProgress()
      if (response.data.progression_data?.finishedSteps) {
        commit('setFinishedSteps', response.data.progression_data.finishedSteps)
      }
      if (response.data.progression_data?.stepsDatas) {
        commit('setStepsDatas', response.data.progression_data.stepsDatas)
      }
      if (response.data.progression_data?.partsDatas) {
        commit('setPartsDatas', response.data.progression_data.partsDatas)
      }
      if (response.data.progression_data?.finalTestDatas) {
        commit('setFinalTestDatas', response.data.progression_data.finalTestDatas)
      }
      commit('setIsMoocValid', response.data?.mooc_status === 'valid')
      commit('setIsDatasLoaded', true)
    },
    async recordFinalTest ({ state, dispatch }, data) {
      await trainingProgressServiceAPI.recordFinalTest({
        ...data,
        testVersion: state.testVersion
      })
      await dispatch('saveProgress')
    }
  }
}

export default trainingStore
