let _id = 0

const notificationStore = {
  namespaced: true,
  state: {
    notifications: []
  },
  getters: {},
  mutations: {
    setNotifications: (state, notifications) => {
      state.notifications = notifications
    }
  },
  actions: {
    notify: ({ state, commit }, notification) => {
      const toCommit = state.notifications
      toCommit.push({ ...notification, id: _id++ })
      commit('setNotifications', toCommit)
    },
    removeNotification: ({ state, commit }, notificationName) => {
      const toCommit = state.notifications
      toCommit.splice(state.notifications.findIndex(notification => notification.name === notificationName), 1)
      commit('setNotifications', toCommit)
    }
  }
}

export default notificationStore
