<template>
  <div class="user-avatar">
    {{ getUserInitials }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserAvatar',
  computed: {
    ...mapGetters('user', ['getUserInitials'])
  }
}
</script>

<style lang="scss" scoped>
.user-avatar {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-image: linear-gradient(to top, #006ea9 0%, #006ea9 25%, #0072a2 32%, #007f8f 43%, #009371 55%, #00a653 65%, #00a653 100%);
  color: #fff;
  text-transform: uppercase;
  font-size: $fz-s;
  font-weight: $fw-l;
}
</style>
