<template>
  <header class="app-header">
    <div class="header-container">
      <!-- LOGO APPLI CLIQUABLE -->
      <img @click="redirectToOscar"
        class="app-logo"
        :src="mq.tabletMinus ? require('@/assets/logos/oscar-vertical.svg') : require('@/assets/logos/oscar.svg')"
        :alt="$t('global.appName')"
      >
      <div v-if="isLoggedIn" class="user-home-button" @click="redirectToOscar">
        <p class="label">{{ $t('global.navigation.dashboard') }}</p>
        <user-avatar></user-avatar>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import UserAvatar from '@/modules/user/components/UserAvatar'
import VIEWS_NAMES from '@/constants/VIEWS_NAMES'
import { inject } from 'vue'
import redirectToOscar from '@/utils/redirectToOscar'

export default {
  name: 'AppHeader',
  components: { UserAvatar },
  setup () {
    const mq = inject('mq')
    return { VIEWS_NAMES, mq, redirectToOscar }
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn'])
  }
}
</script>

<style lang="scss" scoped>
header.app-header {
  background-color: #fff;
  width: 100%;
  height: $header-height;
  display: flex;
  justify-content: center;
  .header-container {
    padding: $space-m;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: $bp-desktop-big;
    .app-logo {
      cursor: pointer;
      width: 150px;
      margin-right: $space-ml;
    }
    .user-home-button {
      cursor: pointer;
      .label {
        display: none;
      }
    }
  }
}

@media (min-width: $bp-tablet) {
  header.app-header {
    .header-container {
      .app-logo {
        width: 360px;
      }
      .user-home-button {
        display: flex;
        align-items: center;
        border: 3px solid $c-secondary;
        border-radius: $radius-xl;
        padding: 3px 3px 3px $space-sm;
        .label {
          display: block;
          margin-right: $space-sm;
          font-weight: $fw-l;
          color: $c-secondary;
          text-transform: uppercase;
        }
      }
    }
  }
}
</style>
