import axios from 'axios'
import apiConfig from '@/api/apiConfig'

const trainingProgressServiceAPI = {
  getProgress () {
    return axios.get(apiConfig.apiUrl + 'user/progress')
  },

  setProgress ({ finishedSteps, stepsDatas, partsDatas, finalTestDatas, modulesStatus }) {
    return axios.post(apiConfig.apiUrl + 'user/progress', {
      module1: modulesStatus.module1,
      module2: modulesStatus.module2,
      module3: modulesStatus.module3,
      progression_data: {
        finishedSteps,
        stepsDatas,
        partsDatas,
        finalTestDatas
      }
    })
  },

  recordFinalTest ({ isSuccess, testVersion, score }) {
    return axios.post(apiConfig.apiUrl + 'progress-event/test', {
      is_success: isSuccess,
      test_version: testVersion,
      test_score: score
    })
  }
}

export default trainingProgressServiceAPI
