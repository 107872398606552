<template>
  <div :class="'notification-message ' + notification.type">
    <p class="icon" v-html="require(`@/assets/icons/${getNotificationIcon(notification.type)}.svg?inline`)"></p>
    <div class="right-col">
      <p class="message" v-html="notification.message"></p>
      <button @click="triggerClose(notification)">
        <span class="close-icon" v-html="require('@/assets/icons/cross.svg?inline')"></span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'notificationMessage',
  emits: ['closeNotification'],
  props: {
    notification: {
      type: Object,
      required: true
    },
    delay: {
      type: Number,
      required: false,
      default () {
        return 5000
      }
    }
  },
  data () {
    return {
      notificationTimer: null
    }
  },
  methods: {
    triggerClose (notification) {
      clearTimeout(this.notificationTimer)
      this.$emit('closeNotification', notification.name)
    },
    getNotificationIcon (notificationType) {
      if (notificationType !== 'error' && notificationType !== 'success') {
        return 'bell'
      } else {
        return notificationType
      }
    }
  },
  mounted () {
    if (this.delay !== -1) {
      this.notificationTimer = setTimeout(() => {
        this.triggerClose(this.notification)
      }, this.delay)
    }
  }
}
</script>

<style lang="scss" scoped>
$notif-opacity: 32;

.notification-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $space-xs $space-s;
  border-radius: $radius;
  font-size: $fz-s;
  .right-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .message {
    margin-top: $space-s;
    margin-bottom: $space-m;
  }
  .icon {
    height: 24px;
  }
  .close-icon {
    width: 20px;
  }
}

.notification-message.info,
.notification-message.default {
  background-color: lighten($c-secondary, $notif-opacity);
  color: $c-secondary;
}

.notification-message.success {
  background-color: #E8F8EF;
  color: $c-primary;
}

.notification-message.error {
  background-color: $c-background;
  color: $c-error;
}

@media (min-width: $bp-tablet) {
  .notification-message {
    padding: $space-s $space-m;
    flex-direction: row;
    font-size: $fz-m;
    .right-col {
      flex-direction: row;
      flex-grow: 1;
    }
    .message {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: $space-m;
      margin-right: $space-l;
    }
  }
}
</style>

<style lang="scss">
.notification-message {
  .close-icon {
    svg {
      width: 20px;
    }
  }
}
.notification-message.info,
.notification-message.default {
  .icon, .close-icon {
    svg path.fill {
      fill: $c-secondary;
    }
    svg path.stroke {
      stroke: $c-secondary;
    }
  }
}
.notification-message.success {
  .icon, .close-icon {
    svg path.fill {
      fill: $c-primary;
    }
    svg path.stroke {
      stroke: $c-primary;
    }
  }
}
.notification-message.error {
  .icon, .close-icon {
    svg path.fill {
      fill: $c-error;
    }
    svg path.stroke {
      stroke: $c-error;
    }
  }
}
</style>
