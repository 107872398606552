import { useStore } from 'vuex'

const useUserAutoLogin = () => {
  const store = useStore()

  const userToken = JSON.parse(sessionStorage.getItem('userToken'))
  const user = JSON.parse(sessionStorage.getItem('user'))

  const autoLogin = () => {
    store.commit('user/setUser', user)
    store.commit('user/setUserToken', userToken)
  }

  return { autoLogin }
}

export default useUserAutoLogin
