import { createRouter, createWebHistory } from 'vue-router'
import VIEWS_NAMES from '@/constants/VIEWS_NAMES'
import i18n, { lazyLoadLocales } from '@/i18n'
import store from '@/store'
import sso from '../views/sso'

const routes = [
  {
    path: '/sso/:targetModule/:userToken',
    name: VIEWS_NAMES.SSO,
    component: sso,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/sommaire-formation/:moduleId',
    name: VIEWS_NAMES.TRAINING_SUMMARY,
    component: () => import(/* webpackChunkName: "trainingSummary" */ '@/views/trainingSummary'),
    meta: {
      requiresAuth: true,
      locales: ['training']
    }
  },
  {
    path: '/formation/:modulePath/:partPath/:stepPath',
    name: VIEWS_NAMES.TRAINING,
    component: () => import(/* webpackChunkName: "training" */ '@/views/training'),
    meta: {
      requiresAuth: true,
      locales: ['training', 'module1', 'module2', 'module3']
    }
  },
  {
    path: '/test-final',
    name: VIEWS_NAMES.FINAL_TEST,
    component: () => import(/* webpackChunkName: "finalTest" */ '@/views/finalTest'),
    meta: {
      requiresAuth: true,
      locales: ['training']
    }
  },
  {
    path: '/panorama-des-aides',
    name: VIEWS_NAMES.PANORAMA_AIDES_INFOGRAPHY,
    component: () => import(/* webpackChunkName: "panoramaAidesInfography" */ '@/views/panoramaAidesInfography'),
    meta: {
      requiresAuth: false,
      locales: ['training', 'module1']
    }
  },
  {
    path: '/erreur',
    name: VIEWS_NAMES.ERROR_TOKEN,
    component: () => import(/* webpackChunkName: "tokenError" */ '@/views/tokenError'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: VIEWS_NAMES.NOT_FOUND,
    component: () => import(/* webpackChunkName: "pageNotFound" */ '@/views/pageNotFound'),
    meta: {
      requiresAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    // always scroll to top left
    return { top: 0, left: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  // Loading des locales
  if (to.meta.locales) {
    const messages = await lazyLoadLocales(to.meta.locales)
    i18n.global.mergeLocaleMessage('fr', messages)
  }
  // Loading des modules du store
  if (typeof to.meta.loadStore === 'function') {
    await to.meta.loadStore()
  }
  // Check de l'auth du user si la page le demande
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['user/isLoggedIn']) { // Utilisateur non loggué
      store.dispatch('user/logout')
      next({ name: VIEWS_NAMES.NOT_FOUND })
    } else {
      next()
    }
  } else {
    next()
  }
})

export { routes }
export default router
